
//
//  Sweetalert2
//

.swal2-container {
  .swal2-title{
    font-size: 24px;
    font-weight: 500;
    color: var(--#{$prefix}body-color);
  }
  .swal2-popup{
    background-color: var(--#{$prefix}secondary-bg);
    background: url("//subtlepatterns2015.subtlepatterns.netdna-cdn.com/patterns/geometry.png") var(--#{$prefix}secondary-bg) !important;
  }
}

.swal2-icon{
  &.swal2-question{
    border-color: $info;
    color: $info;
  }
  &.swal2-success {
    [class^=swal2-success-line]{
      background-color: $success;
    }

    .swal2-success-ring{
      border-color: rgba($success, 0.3);
    }
  }
  &.swal2-warning{
    border-color: $warning;
    color: $warning;
  }
}

.swal2-styled{
  &:focus{
    box-shadow: none;
  }
}

.swal2-content{
  font-size: 16px;
  color: var(--#{$prefix}body-color);
}

.swal2-progress-steps {
  .swal2-progress-step{
    background: $primary;
    &.swal2-active-progress-step{
      background: $primary;
      &~.swal2-progress-step, &~.swal2-progress-step-line{
        background: rgba($primary, 0.3);
      }
    }
  }

  .swal2-progress-step-line{
    background: $primary;
  }
}

.swal2-actions.swal2-loading .swal2-styled.swal2-confirm{
  border-left-color: $primary !important;
  border-right-color: $primary !important;
}

.swal2-html-container{
  color: var(--#{$prefix}body-color) !important;
}