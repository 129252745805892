// 
// authentication.scss
//


// authentication home icon
.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}



.bg-login {
    background-image: url(../../../images/tvk-login.jpg);
    padding: 60px 0px;
    background-size: 100% 109%;
    background-position: center top;
    position: relative;
    height: 100%;
    min-height: 184px;
    // border-radius: 0px 0px 50% 50%;
}




.account-pages {
    .logo-admin {
        position: absolute;
        left: 0;
        right: 0;
        top: 55px;
        margin: 0px auto;
        width: 74px;
        height: 74px;
        line-height: 74px;
        background: $card-bg;
        border-radius: 50%;
        text-align: center;
        box-shadow: $box-shadow;
    }
}

// error page

.error-page {
    text-transform: uppercase;
    font-size: 60px;
    line-height: .7;
    position: relative;
}

.card.overflow-hidden{
    background: rgb(255 255 255 / 80%);
}

.pass i {
    position: relative;
    left: 343px;
    bottom: 68px;
}


.card.overflow-hidden label.form-label {
    color: #707070;
    font-size: 17px;
    font-weight: 500;
}
.invalid-feedback {
    color: #ff000e !important;
}
@media (max-width: 500px) {
    .pass i {
        position: absolute;
        left: 213px;
        top: 343px;
    }
}
.home-btn i {
    color: #d1040e;
}
.log-buttons button {
    background-color: #a1010a;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    border: 1px solid #94030a;
}

.log-buttons button:hover {
    background-color: #3f0205 !important;

}
.account-pages {
    background-image: url(../../../images/login-page.jpg);
    height: 100%;
    max-height: 1000px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 1000px;
    padding-top: 200px !important;
}
// .change {
//     width: 85% !important;
//     height: 260px !important
// }
.changepssword {
    color: #a10101 !important;
    margin-bottom: 40px;
    margin-top: 10px;
    border-bottom: 1px solid #a10101;
}
.change-log {
    background-color: #a1010a;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    border: 1px solid #94030a;
    padding: 10px 10px;
    border-radius: 5px;
}
.current-patten {
    text-align: center;
    font-size: 18px;
}

.change-Pattern{
    background-color: #a1010a;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    border: 1px solid #94030a;
    padding: 10px 10px;
    border-radius: 5px;
    margin:  0 auto;
    display: block;
}
.change-Reset {
    background-color: #707070;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    border: 1px solid #707070;
    padding: 10px 10px;
    border-radius: 5px;
    margin: 10px auto  !important;
    display: block; 
}
.change-new input {
    box-shadow: 0px 4px 7px rgb(112 112 112 / 19%);
    border: 1px solid #8080804f;
}


.position-relative {
    position: relative;
  }
  
  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center; /* Align input and icon content */
  }
  
  .form-control {
    flex: 1; /* Stretch input to fill available space */
    padding-right: 3rem; /* Space for the Eye icon */
    height: 48px; /* Consistent height for input fields */
    box-sizing: border-box; /* Include padding and border in total width/height */
  }
  
  .pass-icon {
    position: absolute;
    top: 69%; /* Adjusted vertical position */
    right: 39px; /* Adjusted horizontal position */
    transform: translateY(-50%); /* Center the icon vertically */
    cursor: pointer; /* Make the icon clickable */
    color: #555; /* Icon color */
    font-size: 1.2rem; /* Adjust size to match input height */
    z-index: 2; /* Keep the icon above other elements */
  }
  
  