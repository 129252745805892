
//
// apexcharts.scss
//
.apex-charts {
    min-height: 10px !important;
    text {
        font-family: $font-family-base !important;
        fill: $gray-500;
    }
    .apexcharts-canvas {
        margin: 0 auto;
    }
}

.apexcharts-tooltip{
    background-color: var(--#{$prefix}card-bg) !important;
    border-color: var(--#{$prefix}border-color) !important;
    .apexcharts-tooltip-title{
    background-color: var(--#{$prefix}card-bg) !important;
    border-color: var(--#{$prefix}border-color) !important;
    }
}
.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
    font-family: $font-family-base !important;
}

.apexcharts-legend-series {
    font-weight: 500;
}

.apexcharts-gridline {
    pointer-events: none;
    stroke: var(--#{$prefix}border-color) !important;
}

.apexcharts-legend-text {
    color: $gray-600 !important;
    font-family: $font-family-base !important;
    font-size: 13px !important;
}

.apexcharts-pie-label {
    fill: $white !important;
}

.apexcharts-yaxis,
.apexcharts-xaxis {
    text {
        font-family: $font-family-base !important;
        fill: $gray-500;
    }
}

.apexcharts-radar-series polygon{
    stroke: var(--#{$prefix}border-color) !important;
    fill: var(--#{$prefix}card-bg) !important;
}

.apexcharts-gridline {
    stroke: #364458;
}


.apexcharts-grid-borders{
    line{
        stroke: var(--#{$prefix}border-color) !important;
    }
}


