//
// avatar.scss
//

.avatar-xs {
  height: 2rem;
  width: 2rem;
}

.avatar-sm {
  height: 2.5rem;
  width: 2.5rem;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-title {
  align-items: center;
  background-color: $primary;
  color: $white;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  width: 100%;
}

// z-index

.z-index {
  position: relative;

  .box-2 {
    top: 13px;
    left: 16px;
  }

  .box-3 {
    top: 25px;
    left: 31px;
  }

  .box-4 {
    top: 39px;
    left: 45px;
  }

  .box-5 {
    top: 52px;
    left: 59px;
  }

}