.site-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: radial-gradient(circle, #007bff 0%, #fff 70%); /* Gradient background */
  animation: pulse 1.5s ease-in-out infinite alternate; /* Pulse animation */
  animation-delay: 0.5s; /* Delay for alternating effect */
  box-shadow: 0 0 20px rgba(0, 123, 255, 0.3); /* Box shadow for a glow effect */
  border: 5px solid transparent; /* Transparent border */
  border-top-color: #007bff; /* Initial border color */
  animation: spin 1s linear infinite; /* Animation definition */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes borderChange {
  0% {
    border-color: #007bff;
  }
  50% {
    border-color: #28a745;
  }
  100% {
    border-color: #dc3545;
  }
}
